import React, { useState, useEffect, useRef } from "react";
// import ModalVideo from 'react-modal-video'
import { Modal } from "react-bootstrap"
// import 'react-modal-video/scss/modal-video.scss';
import { createPortal } from 'react-dom';
import { Link } from "gatsby"
import ReactPlayer from 'react-player'
import { useLocation } from "@reach/router"
import $ from "jquery"

import {
    isIOS,
    isMobileOnly,
    isBrowser,
    isMobile
} from "react-device-detect";

const ModalWindow = ({ children }) => {
    // element to which the modal will be rendered
    const el = document.createElement("div");
    // add a class to the container div for styling
    // el.className = "modal-portal-container";
    useEffect(() => {
        const modalRoot = document.body;
        // append to root when the children of Modal are mounted
        modalRoot.appendChild(el);
        // do a cleanup
        return () => {
            modalRoot.removeChild(el);
        };
    }, [el]);

    return (
        createPortal(
            children,
            el
        )
    )
}


const CustomVideoOne = (props, { children }) => {
    const [isOpen, setOpen] = useState(false);
    const [videotype, setVideoType] = useState('youtube');
    const [youtubeid, setYoutubeID] = useState('youtube');

    const [video_url, setVideoURL] = useState('');

    function checkvideo(url) {
        let url_one = String(url)
        let youtube = url_one.indexOf("youtube.com") > -1
        let matter = url_one.indexOf("matterport.com") > -1
        let matter_model = url_one.indexOf("models") > -1
        if (youtube == true) {
            setVideoType("youtube")
        }
        else if (matter == true) {
            setVideoType("matterport")
        }

    }



    const trackerVideo = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Video Play Btn',
            'formType': event,
            'formId': 'Video Play',
            'formName': 'Video',
            'formLabel': 'Video'
        });
    }

    const [isPlay, setPlay] = useState(false);
    const vidRef = useRef(null);
    const [show, setShow] = useState(false);
    const [play, setPlayvideo] = useState(true);
    const [mute, setMute] = useState(true);
    const [controls, setVideoControls] = useState(false);


    const [showHTMLVideo, setHTMLVideo] = React.useState(false);
    const pausevideo = (ref) => {
        setPlayvideo(false)
        setVideoControls(false)

    }
    const playvideo = (ref) => {
        setPlayvideo(true)
        setVideoControls(false)
        setTimeout(function () { $(".modal-bg-black").hide() }, 1700);
    }
    const showControls = () => {
        setVideoControls(true)
    }

    useEffect(() => {
        if (props.url) {
            checkvideo(props.url)
        }
    }, []);

    return (
        <>
            {showHTMLVideo == false &&
                <React.Fragment>
                    {videotype == 'youtube' ?
                        <div onClick={() => { setHTMLVideo(true); playvideo(); setShow(true); }}>
                            {props.children}
                            <button type="button" title="play" className="btn-play" onClick={() => { setHTMLVideo(true); playvideo(); setShow(true); }}>
                                <i className="icon-play"></i>
                            </button>
                        </div> :
                        <a href={props.url} target="_blank">
                            {props.children}
                            <button type="button" title="play" className="btn-play">
                                <i className="icon-play"></i>
                            </button>
                        </a>
                    }
                </React.Fragment>
            }



            {showHTMLVideo &&
                <div className="inline-video-play">
                    <ReactPlayer frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen autoplay ref={vidRef} onPlay={trackerVideo(props.title)} url={props.url} modestbranding="1" autoplay={true} muted={isMobile && isIOS ? mute : false} playsinline={true} playing={play} onEnded={() => { setHTMLVideo(false) }} width='100%' height='100%' previewTabIndex={0} light={false} controls={true}/>
                </div>
            }

        </>
    )
}

export default CustomVideoOne

